@use "../../styles/variables.module" as *;

.settingsContainer {
  background: white;
  border-radius: 20px;
  padding: 1rem;
  color: $black;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 35vw;
  max-width: 400px;
  border-collapse: collapse;
}
.editable {
  height: 26px;
  min-width: 50px;
}

.itemRow {
  display: flex;
  font-weight: 600;
  flex-direction: row;
  justify-content: space-between;

  button {
    color: #0e509e;
  }

  small {
    font-weight: initial;
  }


  > div {
    vertical-align: middle;
    display: table-cell;

    &:last-of-type {
      text-align: right;
    }

    &:first-of-type {
      text-align: left;
    }

    p {
      margin: 0;
    }
  }
}

.itemIcon {
  font-size: 40px;
}

.laptopIcon {
  font-size: 40px;
  margin-right: 3px;
  margin-left: 0;
  padding-left: 0;
}

.sectionBorder {
  border-top: 2px solid lightgray;
  width: 100%;
  margin: .35em 0 .35em 0
}

.icon {
  font-size: 40px;
  width: 50px;
  margin-right: 10px;
  text-align: center !important;
}

.flexGrow1  {
  flex-grow: 1;
}

.flexGrow5 {
  flex-grow: 5;
}

.subscriptionType {
  width: 50%;
}
