@use "../../../styles/mixins";

.editor {
  // overflow-x: scroll;
  border-radius: 6px;
  padding: 6px 12px;
  border: 1px solid #ccc;
  background-color: #F7F7F7;
  font-size: 18px;

  textarea {
    border: none;
    width: 100%;
    background-color: inherit;
  }

  > div {
    min-height: 150px !important;
  }

  > div[role="textbox"]:focus {
    outline: none;
  }

  h3 {
    margin: 0 0 14px !important;
  }

  p {
    margin:  0 0 10px !important;
  }

  a {
    text-decoration: underline !important;
  }

  del {
    color: inherit;
  }
  li {
    font-size: 14px;
  }
  b {
    font-weight: 700;
  }

  blockquote {
    border-left: 2px solid #ddd !important;
    margin: 0 !important;
    padding: 0 0 0 10px !important;
    padding-left: 10px;
    color: #aaa !important;
    font-style: italic !important;
  }

  li p, blockquote p {
    margin: 0 !important;
  }

}

$nbButtons: 7;
$btnSize: 40px;
$bgColor: #323232;

.container {
  position: relative;

  &RawMarkdown {
    padding-top: 0;
  }

  &Editable {
    padding-top: 45px; // make room for toolbar
  }

  > button {
    color: #378fec;
  }
}

.toolbar {
  position: absolute;
  background: $bgColor;
  width: $btnSize * $nbButtons;
  height: 40px;
  transition: all .25s linear;
  border-radius: 10px;
  z-index: 2;

  .arrow {
    border-style: solid;
    display: block;
    height: 12px;
    margin-left: -8px;
    margin-top: -2px;
    position: absolute;
    width: 12px;
    border-width: 8px 8px 0;
    border-color: $bgColor transparent transparent;
    top: $btnSize;
    transition: all .25s ease;
  }

  .button[disabled], .button:disabled {
    opacity: .5 !important;
    pointer-events: none !important;
    cursor: default !important;
  }

  &Expand {
    width: 200px + $btnSize * 3;

    button:first-of-type {
      border-radius: 0 !important;
      border: 0 !important;
    }
  }

  input {
    width: 200px;
    height: $btnSize;
    padding: 6px;
    background: $bgColor !important;
    color: white;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin: 0;
  }

  .button {
    background: $bgColor;
    color: white;
    width: $btnSize;
    height: $btnSize;
    padding: 0;
    vertical-align: top;
    margin: 0;
    outline: 0;
    cursor: pointer;
    border: 0;
    transition: background-color .25s ease;
    text-align: center;

    &:not(:last-of-type) {
      border-right: 1px solid #828282;
    }

    &:first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-of-type {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &.buttonActive {
      background: black;
    }

    &:hover {
      background: black;
    }
  }
}

.menu {
  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 15px;
  }
}

.tooltip {
  @include mixins.tooltip();
}

.placeholder {
  position: absolute;
  pointer-events: none;
  color: gray;
}
